import styled from "styled-components";
import React from "react";
import Navbar from "../navbar/Navbar";

const Layout = (props) => {
    const { children } = props;

    return (
        <>
            <Navbar />
            <Main>{children}</Main>
        </>
    );
};

export default Layout;

const Main = styled.main`
    padding: calc(4rem + 70px) 15% 0 15%;
    background-color: #f9f9f9;
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    transition: all 0.6s;

    @media (max-width: 1654px) {
        padding: calc(2rem + 70px) 10% 0 10%;
    }
    @media (max-width: 1440px) {
        padding: calc(2rem + 70px) 8% 0 8%;
    }

    @media (max-width: 768px) {
        padding: calc(2rem + 70px) 2rem 0 2rem;
    }
`;
