import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Snack = (props) => {
	const { open, handleClose, severity, color } = props;

	// RETURN
	return (
		<Snackbar
			open={open}
			autoHideDuration={5000}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
		>
			<MuiAlert
				elevation={6}
				variant="filled"
				onClose={handleClose}
				severity={severity}
				style={{
					backgroundColor: color,
					color: "#ffffff",
					fontWeight: "bold",
					height: "max-content",
				}}
			>
				{props.children}
			</MuiAlert>
		</Snackbar>
	);
};

export default Snack;
