import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Snack from "../components/common/Snack";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";

import axios from "axios";
import { useParams } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import image from "../assets/images/error-blue.png";

const Constancy = () => {
    const { id, tipo } = useParams();
    const [event, setEvent] = useState(null);

    const [certificado, setCertificado] = useState(null);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getEvent = () => {
            setIsLoading(true);
            axios({
                method: "POST",
                url: "https://sisapi.aden.org/api/evento",
                data: { id_evento: parseInt(id) },
            })
                .then((res) => {
                    setEvent(res.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setEvent("error");
                    setErrorMessage("Evento no encontrado");
                    setIsLoading(false);
                });
        };

        if (event === null) {
            getEvent();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, isLoading]);

    function handleErrorClose() {
        setErrorOpen(false);
    }

    const generateImage = () => {
        window.html2canvas = html2canvas;
        let content = document.getElementById("certificado");

        const options = {
            background: "white",
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
        };

        html2canvas(content, options).then((canvas) => {
            var a = document.createElement("a");
            a.href = canvas.toDataURL("image/PNG").replace("image/PNG", "image/octet-stream");
            a.download = "Constancia.png";
            a.click();
        });
    };

    const generatePDF = () => {
        window.html2canvas = html2canvas;

        let content = document.getElementById("certificado");

        const options = {
            background: "white",
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
        };

        html2canvas(content, options)
            .then((canvas) => {
                const img = canvas.toDataURL("image/PNG");
                let doc = new jsPDF("l", "cm", "a4");

                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                const marginY = (pageHeight - canvasHeight) / 2;

                doc.addImage(img, "PNG", marginX, marginY, canvasWidth, canvasHeight, undefined, "FAST");

                return doc;
            })
            .then((doc) => {
                doc.save(`${tipo}-${event.nombre_mostrado}.pdf`);
            });
    };

    const getCertificado = async () => {
        axios({
            method: "POST",
            url: "https://sisapi.aden.org/api/v1/acropolis/events/achievement",
            data: { email: email, event_id: event.id },
        })
            .then((res) => {
                console.log(res);
                setCertificado(res.data.response_data);
                const nombre_evento = document.getElementById("event-name");
                if (nombre_evento != null) {
                    nombre_evento.innerHTML = event.nombre_mostrado;
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorMessage(error.response.data.error);
                    setErrorOpen(true);
                } else {
                    setErrorMessage("No pudimos encontrar una constancia para el mail ingresado");
                }
            });
    };

    if (event != null && event !== "error") {
        return (
            <>
                <Container>
                    <Subtitulo>¡Consigue tu {tipo} de participación!</Subtitulo>
                    <NombreEvento>{event.nombre_mostrado}</NombreEvento>
                    <Paso>
                        <div className='nro'>1</div>
                        <div className='texto'>Ingresa el correo electrónico con el que te registraste al evento</div>
                    </Paso>
                    <Email>
                        <TextField
                            id='msj'
                            value={email}
                            label='E-mail'
                            placeholder='Escribe tu e-mail aquí'
                            fullWidth
                            margin='normal'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button variant='contained' color='primary' onClick={getCertificado}>
                            Siguiente
                        </Button>
                    </Email>
                </Container>

                {certificado != null && (
                    <Container>
                        <Paso>
                            <div className='nro'>2</div>
                            <div className='texto'>¡Descarga tu {tipo}!</div>
                        </Paso>
                        <ButtonList>
                            <Button
                                endIcon={<PictureAsPdfRoundedIcon />}
                                variant='contained'
                                color='primary'
                                onClick={generatePDF}
                            >
                                Descargar como PDF
                            </Button>
                            <Button
                                endIcon={<PhotoLibraryRoundedIcon />}
                                variant='contained'
                                color='primary'
                                onClick={generateImage}
                            >
                                Descargar como Imagen
                            </Button>
                        </ButtonList>
                        {certificado.html && (
                            <Certificado id='pdf' dangerouslySetInnerHTML={{ __html: certificado.html }}></Certificado>
                        )}
                    </Container>
                )}

                <Snack open={errorOpen} handleClose={handleErrorClose} severity='warning' color='#ff9800'>
                    {errorMessage}
                </Snack>
            </>
        );
    }

    if (event === null || isLoading) {
        return (
            <Container>
                <Spinner></Spinner>
            </Container>
        );
    }

    if (event === "error") {
        return (
            <ContainerError>
                <Image src={image} alt='Evento no encontrado' />
                <Title>No hemos encontrado el evento solicitado</Title>
                <SubTitle>
                    Si crees que es un error puedes dejar tu reclamo en la sección de{" "}
                    <a href='https://campus.adenu.edu.pa/peticiones'>Peticiones y Reclamos</a> de nuestro Campus
                    Académico.
                </SubTitle>
                <Button variant='contained' color='primary' href='https://campus.adenu.edu.pa'>
                    Ir al campus
                </Button>
            </ContainerError>
        );
    }
};

export default Constancy;

const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
`;

const ContainerError = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 4rem;
    box-sizing: border-box;
`;

const NombreEvento = styled.div`
    color: #6181ff;
    font-size: 2rem;
    font-weight: 900;
    line-height: 150%;
    padding: 2rem 0;
`;

const Image = styled.img`
    width: 40%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #6181ff;
    margin: 2rem 0 0 0;
`;

const SubTitle = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #616161;

    a {
        color: #6181ff;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
`;

const Subtitulo = styled.div`
    color: #616161;
    font-size: 1rem;
`;

const Paso = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;

    .nro {
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #6181ff;
        color: #ffffff;
        font-weight: 700;
    }

    .texto {
        font-size: 1.2rem;
        color: #222222;
        font-weight: 700;
        text-align: left;
    }
`;

const Email = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    column-gap: 1rem;
    row-gap: 1.5rem;
    padding: 1rem;

    .MuiFormControl-marginNormal {
        margin: 0 !important;
    }
`;

const Certificado = styled.div`
    max-width: 100%;
    @media (max-width: 1260px) {
        overflow: scroll;
    }
`;

const ButtonList = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem;

    @media (max-width: 768px) {
        align-items: center;
    }
`;
