import React from "react";

import styled from "styled-components";
import { Button } from "@material-ui/core";

import image from "../assets/images/404-error.svg";

const NotFound = () => {
    return (
        <Container>
            <Image src={image} alt='Pagina no encontrada' />
            <Title>Página no encontrada</Title>
            <SubTitle>
                Te invitamos a ingresar al campus, si crees que es un error puedes dejar tu reclamo en la sección de{" "}
                <a href='https://campus.adenu.edu.pa/peticiones'>Peticiones y Reclamos</a>
            </SubTitle>
            <Button variant='contained' color='primary' href='https://campus.adenu.edu.pa'>
                Ir al campus
            </Button>
        </Container>
    );
};

export default NotFound;

const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 2rem;
    gap: 1rem;
`;

const Image = styled.img`
    width: 40%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    color: #6181ff;
    margin: 0;
`;

const SubTitle = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #616161;

    a {
        color: #6181ff;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
`;
